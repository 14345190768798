import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fads-core%2Fsrc%2Fcomponents%2FGridItem%2FGridItem.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62Uy26DMBBF93yFl41kooTQPDf9FQecxAmP1JAmVcW%2FVyKq4jF0PEasmTkXzD2eZhels%2FOV%2FQSMpaq6ZuJ7yw6ZfOyCJpg%2Bn362T49apaEu72FVC11v2ZfQb2H4nJhNjHENx2WRwuF3c7h6DQstBZwU5mT9mkzK7JYXfS9yMDdunY3Ou1za%2BY9cpkqwKtFSFkwUKXvLVRHeVVqftmy1XF8fk5YFDoSxP8ocQufcOpzJrh3HTrB%2Fp3llaitzCfeX3DpjO7Pz6f0bRmJlJe7h%2Fp5b%2F8pM7P7L%2FnEjrrbijnD%2FyK0fbcb9X4j%2BNSP2ZsVmcD%2FjVl96YjtH27%2FUOJs2n0Wxo2oRTIq4o0WE6tEYSBVXkLfijp45q0kjIFVNIC%2Fhjiri1aWtI1U%2BQd6JOypKqzYNg1Q9h7ycO0pMqT4NQlAhjmcOFRYwecE9W01QYxgTUWUN%2BWvu2XunOsOIiEoWP%2BWeauBqDcMhqinIV5DgVoam3jAsomIB%2BQUkuKWiqDkMSlB1E7lUjeGbxDDL3zKCuuNkICpvYN4GIvw9dKo9TgKiuoR5EiL8VcXVHwePXAVnmHeGCH%2BFaVfDODHIVVHCvBIi%2FCWnXB3jhDRB8wt6wdE%2FcA4AAA%3D%3D%22%7D"
export var gridArea = 'lkirljs';
export var gridAreaVars = {value:'gridArea',vars:{initial:'var(--lkirlja)',sm:'var(--lkirljb)',md:'var(--lkirljc)',lg:'var(--lkirljd)',xl:'var(--lkirlje)'},extractedVars:{initial:'--lkirlja',sm:'--lkirljb',md:'--lkirljc',lg:'--lkirljd',xl:'--lkirlje'}};
export var gridColumnEnd = 'lkirlju';
export var gridColumnEndVars = {value:'gridColumnEnd',vars:{initial:'var(--lkirljk)',sm:'var(--lkirljl)',md:'var(--lkirljm)',lg:'var(--lkirljn)',xl:'var(--lkirljo)'},extractedVars:{initial:'--lkirljk',sm:'--lkirljl',md:'--lkirljm',lg:'--lkirljn',xl:'--lkirljo'}};
export var gridColumnStart = 'lkirljt';
export var gridColumnStartVars = {value:'gridColumnStart',vars:{initial:'var(--lkirljf)',sm:'var(--lkirljg)',md:'var(--lkirljh)',lg:'var(--lkirlji)',xl:'var(--lkirljj)'},extractedVars:{initial:'--lkirljf',sm:'--lkirljg',md:'--lkirljh',lg:'--lkirlji',xl:'--lkirljj'}};
export var gridRowEnd = 'lkirljr';
export var gridRowEndVars = {value:'gridRowEnd',vars:{initial:'var(--lkirlj5)',sm:'var(--lkirlj6)',md:'var(--lkirlj7)',lg:'var(--lkirlj8)',xl:'var(--lkirlj9)'},extractedVars:{initial:'--lkirlj5',sm:'--lkirlj6',md:'--lkirlj7',lg:'--lkirlj8',xl:'--lkirlj9'}};
export var gridRowStart = 'lkirljq';
export var gridRowStartVars = {value:'gridRowStart',vars:{initial:'var(--lkirlj0)',sm:'var(--lkirlj1)',md:'var(--lkirlj2)',lg:'var(--lkirlj3)',xl:'var(--lkirlj4)'},extractedVars:{initial:'--lkirlj0',sm:'--lkirlj1',md:'--lkirlj2',lg:'--lkirlj3',xl:'--lkirlj4'}};
export var root = 'lkirljp';