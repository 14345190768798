/// <reference types="vite-plugin-svgr/client" />

import { forwardRef } from 'react';
import { IconVariants } from '../components/Icon/Icon.css';

import { IconWrapper } from '../components/Icon';
import { InternalComponentProps } from '../components/Slot';
import ArrowRightIconSvg from './../../static/icons/arrow-right.svg?react';
import ArrowLeftIconSvg from './../../static/icons/arrow-left.svg?react';
import ArrowDownIconSvg from './../../static/icons/arrow-down.svg?react';
import ChevronDownIconSvg from './../../static/icons/chevron-down.svg?react';
import ChevronUpIconSvg from './../../static/icons/chevron-up.svg?react';
import ChevronLeftIconSvg from './../../static/icons/chevron-left.svg?react';
import ChevronRightIconSvg from './../../static/icons/chevron-right.svg?react';
import SearchIconSvg from './../../static/icons/search.svg?react';
import FacebookIconSvg from './../../static/icons/facebook.svg?react';
import LinkedinIconSvg from './../../static/icons/linkedin.svg?react';
import XIconSvg from './../../static/icons/logo-x.svg?react';
import InstagramIconSvg from './../../static/icons/instagram.svg?react';
import PersonIconSvg from './../../static/icons/person.svg?react';
import MenuIconSvg from './../../static/icons/menu.svg?react';
import CloseIconSvg from './../../static/icons/close.svg?react';
import InfoIconSvg from './../../static/icons/info.svg?react';
import ExternalIconSvg from './../../static/icons/external.svg?react';
import WarningIconSvg from './../../static/icons/warning.svg?react';
import DownloadIconSvg from './../../static/icons/download.svg?react';
import EditIconSvg from './../../static/icons/edit.svg?react';
import CheckmarkIconSvg from './../../static/icons/checkmark.svg?react';
import UploadIconSvg from './../../static/icons/upload.svg?react';
import CalendarIconSvg from './../../static/icons/calendar.svg?react';
import PinDropIconSvg from './../../static/icons/pin-drop.svg?react';
import PersonPinIconSvg from './../../static/icons/person-pin.svg?react';
import PlaySvg from './../../static/icons/play.svg?react';
import LoadSvg from './../../static/icons/load.svg?react';
import FlagIconSvg from './../../static/icons/flag.svg?react';
import PhoneAndroidSvg from './../../static/icons/phone-android.svg?react';
import DashboardSvg from './../../static/icons/dashboard.svg?react';
import FrameSourceSvg from './../../static/icons/frame-source.svg?react';
import SettingsSvg from './../../static/icons/settings.svg?react';
import ResponsiveLayoutSvg from './../../static/icons/responsive-layout.svg?react';
import RocketLaunchSvg from './../../static/icons/rocket-launch.svg?react';
import RouteSvg from './../../static/icons/route.svg?react';
import SpeedSvg from './../../static/icons/speed.svg?react';
import TrackpadInputSvg from './../../static/icons/trackpad-input.svg?react';
import VisibilitySvg from './../../static/icons/visibility.svg?react';
import WysiwygSvg from './../../static/icons/wysiwyg.svg?react';
import ChecklistSvg from './../../static/icons/checklist.svg?react';
import NoElectricalConnectionIconSvg from './../../static/icons/no-electrical-connection.svg?react';
import NoGasConnectionIconSvg from './../../static/icons/no-gas-connection.svg?react';
import FilterListIconSvg from './../../static/icons/filter-list.svg?react';

import BeFlagSvg from './../../static/flags/be.svg?react';
import NlFlagSvg from './../../static/flags/nl.svg?react';
import DeFlagSvg from './../../static/flags/de.svg?react';
import EuFlagSvg from './../../static/flags/eu.svg?react';

// TODO: dynamically import them all, loop through them and export them all

type IconProps = IconVariants & InternalComponentProps;

export const ArrowRightIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowRightIconSvg} {...props} ref={ref} />;
  }
);

export const ArrowLeftIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowLeftIconSvg} {...props} ref={ref} />;
  }
);

export const ArrowDownIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ArrowDownIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronUpIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronUpIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronRightIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronRightIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronLeftIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronLeftIconSvg} {...props} ref={ref} />;
  }
);

export const ChevronDownIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChevronDownIconSvg} {...props} ref={ref} />;
  }
);

export const SearchIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SearchIconSvg} {...props} ref={ref} />;
  }
);

export const FacebookIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FacebookIconSvg} {...props} ref={ref} />;
  }
);

export const LinkedinIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={LinkedinIconSvg} {...props} ref={ref} />;
  }
);

export const XIcon = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={XIconSvg} {...props} ref={ref} />;
});

export const InstagramIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={InstagramIconSvg} {...props} ref={ref} />;
  }
);

export const PersonIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PersonIconSvg} {...props} ref={ref} />;
  }
);

export const PersonPinIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PersonPinIconSvg} {...props} ref={ref} />;
  }
);

export const MenuIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={MenuIconSvg} {...props} ref={ref} />;
  }
);

export const CloseIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CloseIconSvg} {...props} ref={ref} />;
  }
);

export const InfoIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={InfoIconSvg} {...props} ref={ref} />;
  }
);

export const ExternalIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ExternalIconSvg} {...props} ref={ref} />;
  }
);

export const WarningIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={WarningIconSvg} {...props} ref={ref} />;
  }
);

export const DownloadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={DownloadIconSvg} {...props} ref={ref} />;
  }
);

export const EditIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={EditIconSvg} {...props} ref={ref} />;
  }
);

export const CheckmarkIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CheckmarkIconSvg} {...props} ref={ref} />;
  }
);

export const UploadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={UploadIconSvg} {...props} ref={ref} />;
  }
);

export const CalendarIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={CalendarIconSvg} {...props} ref={ref} />;
  }
);

export const PinDropIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PinDropIconSvg} {...props} ref={ref} />;
  }
);

export const PlayIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PlaySvg} {...props} ref={ref} />;
  }
);

export const LoadIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={LoadSvg} {...props} ref={ref} />;
  }
);

export const FlagIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FlagIconSvg} {...props} ref={ref} />;
  }
);

export const FilterList = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FilterListIconSvg} {...props} ref={ref} />;
  }
);

export const NoElectricalConnectionIcon = forwardRef<
  HTMLOrSVGElement,
  IconProps
>((props, ref) => {
  return (
    <IconWrapper svg={NoElectricalConnectionIconSvg} {...props} ref={ref} />
  );
});

export const NoGasConnectionIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={NoGasConnectionIconSvg} {...props} ref={ref} />;
  }
);

export const DashboardIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={DashboardSvg} {...props} ref={ref} />;
  }
);
export const FrameSourceIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={FrameSourceSvg} {...props} ref={ref} />;
  }
);
export const RocketLaunchIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={RocketLaunchSvg} {...props} ref={ref} />;
  }
);
export const RouteIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={RouteSvg} {...props} ref={ref} />;
  }
);
export const SpeedIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SpeedSvg} {...props} ref={ref} />;
  }
);
export const VisibilityIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={VisibilitySvg} {...props} ref={ref} />;
  }
);
export const WysiwygIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={WysiwygSvg} {...props} ref={ref} />;
  }
);

export const ResponsiveLayoutIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ResponsiveLayoutSvg} {...props} ref={ref} />;
  }
);

export const SettingsIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={SettingsSvg} {...props} ref={ref} />;
  }
);

export const TrackpadInputIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={TrackpadInputSvg} {...props} ref={ref} />;
  }
);

export const ChecklistIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={ChecklistSvg} {...props} ref={ref} />;
  }
);

export const PhoneAndroidIcon = forwardRef<HTMLOrSVGElement, IconProps>(
  (props, ref) => {
    return <IconWrapper svg={PhoneAndroidSvg} {...props} ref={ref} />;
  }
);

export const BeFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={BeFlagSvg} {...props} ref={ref} />;
});

export const NlFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={NlFlagSvg} {...props} ref={ref} />;
});

export const DeFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={DeFlagSvg} {...props} ref={ref} />;
});

export const EuFlag = forwardRef<HTMLOrSVGElement, IconProps>((props, ref) => {
  return <IconWrapper svg={EuFlagSvg} {...props} ref={ref} />;
});
